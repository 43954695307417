import firebase from 'gatsby-plugin-firebase';
import React, { useState } from 'react';
import Button from '@atoms/Button/Button';
import Divider from '@atoms/Divider/Divider';
import InputText from '@atoms/InputText/InputText';
import Loader from '@atoms/Loader/Loader';
import Module from '@atoms/Module/Module';
import Section from '@atoms/Section/Section';
import SelectInput from '@atoms/SelectInput/SelectInput';
import SingleCheckbox from '@atoms/SingleCheckbox/SingleCheckbox';
import Text from '@atoms/Text/Text';
import Title from '@atoms/Title/Title';
import './SupportersSignup.scss';

const age_items = [
    {
        value: '18-24',
        label: '18-24',
    },
    {
        value: '25-29',
        label: '25-29',
    },
    {
        value: '30-34',
        label: '30-34',
    },
    {
        value: '35-39',
        label: '35-39',
    },
    {
        value: '40-44',
        label: '40-44',
    },
    {
        value: '45-49',
        label: '45-49',
    },
    {
        value: '50-54',
        label: '50-54',
    },
    {
        value: '55-59',
        label: '55-59',
    },
    {
        value: '60-Plus',
        label: '60+',
    },
];

function SupportersSignup(props) {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [age, setAge] = useState('');
    const [os] = useState('');
    const [agreeOnTerms, setAgreeOnTerms] = useState(false);
    const [requestSuccess, setRequestSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [requestHasBeenSent, setRequestHasBeenSent] = useState(false);

    let mod_class = ['SupportersSignup'];
    mod_class = mod_class.join(' ');

    // Error validation
    const [emailError, setEmailError] = useState();
    let _emailState;

    function checkErrorEmail() {
        const email_pattern = new RegExp(
            /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
        );

        if (!email_pattern.test(email)) {
            _emailState = true;
            console.log('Please enter valid email address.');
        } else {
            _emailState = false;
            console.log('email is all good! ');
        }

        setEmailError(_emailState);
    }

    const handleTerms = (e) => {
        const state = e.target.checked;
        // Save creation date on request
        setAgreeOnTerms(state);
    };

    // Validate form
    function onButtonClick(e) {
        e.preventDefault();

        checkErrorEmail();

        if (_emailState === false) {
            // SEND TO FIREBASE!

            // Add to Firestore
            if (!requestHasBeenSent) {
                setIsLoading(true);
                setRequestHasBeenSent(true);

                firebase
                    .firestore()
                    .collection('userTestingSupporters')
                    .add({
                        name: name,
                        email: email,
                        age: age,
                        os: os,
                        agreeOnTerms: agreeOnTerms,
                    })
                    .then(async function (docRef) {
                        setRequestSuccess(true);
                        setIsLoading(false);
                    })
                    .catch(async function (error) {
                        setIsLoading(false);
                        console.error('Error writing document: ', error);
                    });
            }
        }
    }

    return (
        <div className={mod_class}>
            <Module highlighted padding="l">
                {!requestSuccess ? (
                    <>
                        <Title align="center" size="l">
                            Register as a tester
                        </Title>

                        <Divider spacing="m" invisible />

                        <form>
                            <div className="g_clearfix g_2_3 g_center">
                                <div>
                                    <InputText
                                        id="c_name"
                                        type="text"
                                        label="Name"
                                        autoComplete="off"
                                        onChange={(e) =>
                                            setName(e.target.value)
                                        }
                                    />
                                </div>
                                <div>
                                    <SelectInput
                                        items={age_items}
                                        id="c_age"
                                        label="Alter"
                                        value={age}
                                        onChange={(e) => setAge(e.target.value)}
                                    />
                                </div>
                                <div>
                                    <InputText
                                        id="c_email"
                                        type="email"
                                        required={true}
                                        error={emailError}
                                        helperText={
                                            emailError
                                                ? 'Bitte gültige E-Mail angeben'
                                                : ''
                                        }
                                        label="E-Mail"
                                        autoComplete="email"
                                        onChange={(e) =>
                                            setEmail(e.target.value)
                                        }
                                    />
                                </div>
                            </div>
                        </form>

                        <Section>
                            <Divider spacing="xs" invisible />
                            <div className="g_center">
                                <SingleCheckbox
                                    id="id_Terms"
                                    onChange={(e) => handleTerms(e)}
                                >
                                    Ich stimme den{' '}
                                    <a
                                        href="https://aepsy.com/terms/"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Nutzungsbedingungen
                                    </a>{' '}
                                    zu
                                </SingleCheckbox>
                            </div>
                            <Divider spacing="s" invisible />
                            <div className="g_center g_clearfix">
                                <Button
                                    onClick={(e) => onButtonClick(e)}
                                    label="Register"
                                    theme="highlighted"
                                    size="l"
                                    disabled={!agreeOnTerms}
                                />
                            </div>
                        </Section>
                    </>
                ) : (
                    <>
                        <Title align="center">
                            Thanks, we'll contact you shortly 🙏
                        </Title>
                        <Text align="center">
                            We are looking forward to get your feedback.
                        </Text>
                        <Divider spacing="m" invisible />
                    </>
                )}
            </Module>

            {isLoading && <Loader fullscreen />}
        </div>
    );
}

export default SupportersSignup;
