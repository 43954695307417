import { graphql } from 'gatsby';
import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Button from '@atoms/Button/Button';
import Divider from '@atoms/Divider/Divider';
import Section from '@atoms/Section/Section';
import Text from '@atoms/Text/Text';
import Title from '@atoms/Title/Title';
import Footer from '@components/Footer/Footer';
import HeaderMinimal from '@components/HeaderMinimal/HeaderMinimal';
import HeaderNav from '@components/HeaderNavV2/HeaderNav';
import Layout from '@components/layout';
import Seo from '@components/seo';
import SupportersSignup from '@components/SupportersSignup/SupportersSignup';
import { withLocale } from '@hocs';

export const query = graphql`
    query($language: String!) {
        locales: allLocale(
            filter: { ns: { in: ["index"] }, language: { eq: $language } }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        headerMobileImage: file(
            relativePath: { eq: "supporters/supporters-header-mobile.png" }
        ) {
            childImageSharp {
                fluid(maxWidth: 375, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        headerDesktopImage: file(
            relativePath: { eq: "supporters/supporters-header-desktop.png" }
        ) {
            childImageSharp {
                fluid(quality: 90, maxWidth: 1420) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`;

const SupporterPage = ({ data: graphData }) => {
    const header_image_sources = [
        graphData.headerMobileImage.childImageSharp.fluid,
        {
            ...graphData.headerDesktopImage.childImageSharp.fluid,
            media: `(min-width: 750px)`,
        },
    ];

    return (
        <Layout>
            <Seo
                title="Join the early testing group"
                description="We are looking for testers, join the early user testing group now"
            />
            <div className="global_theme-green">
                <HeaderNav theme="white" />
                <Section theme="light">
                    <HeaderMinimal
                        theme="light"
                        title="Join the early testing group"
                        btnPrimary={
                            <AnchorLink offset={() => 40} href="#signup">
                                <Button
                                    variant="primary"
                                    theme="white"
                                    label="Sign up"
                                    size="l"
                                />
                            </AnchorLink>
                        }
                        imageSrc={header_image_sources}
                    />
                </Section>

                {/* Providers */}
                <div id="signup">
                    <Section spacingBottom="l" spacingTop="m">
                        {/* Introduction */}
                        <Section container="short" spacingBottom="s">
                            <div className="g_2_3 g_center">
                                <Title align="left" size="l">
                                    We can’t wait to have you joining the Aepsy
                                    journey!
                                </Title>
                            </div>

                            <div className="g_1_4 g_center">
                                <Divider spacing="xs" invisible />
                            </div>

                            <div className="g_2_3 g_center">
                                <Text align="left">
                                    We are launching a new app bringing tools,
                                    learning, exercices alongside the
                                    possibility to book and do a psychological
                                    session. As we are starting this new
                                    journey, we would love to get your feedback
                                    and have the possibility to have a short
                                    interview. We can’t wait to have you join
                                    the journey.
                                </Text>
                            </div>

                            <div className="g_1_4 g_center">
                                <Divider spacing="m" invisible />
                            </div>

                            <div className="g_2_3 g_center">
                                {/* <Text align="center" tag="div">
									What does it mean?
								</Text> */}
                                <Title align="left" size="m">
                                    What you will get?
                                </Title>
                                <Divider invisible />
                                <Text align="left" tag="ul">
                                    <li>- Give us feedback</li>
                                    <li>
                                        - Be available for short interviews
                                        (5-10min)
                                    </li>
                                    <li>- Get early access to the app</li>
                                </Text>
                            </div>
                        </Section>

                        <Section container="short" spacingBottom="s">
                            <SupportersSignup />
                        </Section>
                    </Section>
                </div>

                <Footer />
            </div>
        </Layout>
    );
};

export default withLocale(SupporterPage);
